import { graphql } from 'gatsby';
import React, { useContext, useLayoutEffect, useRef, useState } from 'react';
import { css } from '@emotion/react';
import { ThemeContext } from '../components/theme-provider';
import SEO from '../components/seo';
import BlockContent from '../components/block-content';
import Logo from '../components/logo';
import ImageLink from '../components/image-link';
import Box from '../components/box';
import PagePaddingTop from '../components/page-padding-top';

export default function Us({ data }) {
  const theme = useContext(ThemeContext);

  useLayoutEffect(() => {
    theme.setHeaderHide(false);
    theme.setLogoHide(false);
    theme.setLogoInvert(false);
    theme.setBackURL('/');
  }, []);

  return (
    <PagePaddingTop>
      <SEO
        title="Us"
        description={
          data.us._rawIntroText
            ? data.us._rawIntroText
                .map((el) => el.children)
                .flat()
                .map((el) => el?.text)
                .join(' ')
            : null
        }
      />
      <Box
        x={10}
        y={8}
        css={css`
          & + div {
            margin-top: 6rem;
          }
        `}
      >
        <BlockContent className="type--heading-three" content={data.us._rawIntroText} />
      </Box>
      <div
        css={css`
          > div {
            height: 100vh;

            @media (max-width: 800px) {
              height: 70.22vw;
            }

            @media (max-width: 550px) {
              height: 104.5vw;
            }
          }
        `}
      >
        <div>
          <ImageLink
            text="Studio"
            url="/studio"
            image={data.us.studio}
            popupText={data.us._rawStudioText}
          />
        </div>
        <div>
          <ImageLink
            text="Ventures"
            url="/ventures"
            image={data.us.ventures}
            popupText={data.us._rawVenturesText}
          />
        </div>
        <div>
          <ImageLink
            text="People"
            url="/people"
            image={data.us.people}
            popupText={data.us._rawPeopleText}
          />
        </div>
        <div>
          <ImageLink
            text="Careers"
            url="/careers"
            image={data.us.careers}
            popupText={data.us._rawCareersText}
          />
        </div>
      </div>
    </PagePaddingTop>
  );
}

export const query = graphql`
  query {
    us: sanityUs(_id: { regex: "/(drafts\\\\.us)?/" }) {
      _rawIntroText(resolveReferences: { maxDepth: 10 })
      studio {
        ...Image
      }
      _rawStudioText(resolveReferences: { maxDepth: 10 })
      ventures {
        ...Image
      }
      _rawVenturesText(resolveReferences: { maxDepth: 10 })
      people {
        ...Image
      }
      _rawPeopleText(resolveReferences: { maxDepth: 10 })
      careers {
        ...Image
      }
      _rawCareersText(resolveReferences: { maxDepth: 10 })
    }
  }
`;
